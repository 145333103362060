import React from "react";
import Form from "react-bootstrap/Form";

import { FormCheckboxTypes } from "types/forms";

function FilterCheckbox({
  handleCheckboxClick,
  id,
  isChecked,
  type,
  value,
}: {
  handleCheckboxClick: (event: string) => void;
  id: string;
  isChecked: boolean;
  type: FormCheckboxTypes;
  value: string;
}) {
  return (
    <Form.Check
      checked={isChecked}
      className="FilterCheckbox"
      id={`default-${id}`}
      label={value}
      onChange={() => handleCheckboxClick(id)}
      type={type}
    />
  );
}

export default FilterCheckbox;
