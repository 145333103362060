import {
  DrupalFiltersPropertyKeys,
  SelectedFilters,
  DrupalFilters,
} from "components/filters/types";
import { QueryOperators } from "types/operators";
import { AcademicYearTermItem, TermFilterItem } from "types/taxonomyTerms";
import {
  QueryConjunction,
  EntityQueryFilterConditionInput,
  EntityQueryFilterInput,
} from "types/queries";
import getDefaultBaseConditions from "./getDefaultBaseConditions";

function convertSelectedFiltersToConditions(
  selectedFilters: SelectedFilters,
  taxonomyFilters: DrupalFilters
): EntityQueryFilterInput {
  let baseConditions: Array<EntityQueryFilterConditionInput> = getDefaultBaseConditions();

  let contributionsConjunction: EntityQueryFilterInput = {};
  if (selectedFilters[DrupalFiltersPropertyKeys.contributions].length > 0) {
    const contributionConditions: Array<EntityQueryFilterInput> = selectedFilters[
      DrupalFiltersPropertyKeys.contributions
    ].map((selectedContribution) => {
      const contributionQueryFilter: EntityQueryFilterConditionInput = {
        field: "field_fields_of_contribution",
        operator: QueryOperators.IN,
        value: [selectedContribution],
      };

      return {
        conditions: [contributionQueryFilter],
      };
    });

    contributionsConjunction = {
      conjunction: QueryConjunction.AND,
      groups: [...contributionConditions],
    };
  }

  let intersectionalityConjunction: EntityQueryFilterInput = {};
  if (selectedFilters[DrupalFiltersPropertyKeys.intersectionality].length > 0) {
    const intersectionaltyConditions: Array<EntityQueryFilterInput> = selectedFilters[
      DrupalFiltersPropertyKeys.intersectionality
    ].map((selectedIntersectionality) => {
      const intersectionalityQueryFilter: EntityQueryFilterConditionInput = {
        field: "field_intersectionality",
        operator: QueryOperators.IN,
        value: [selectedIntersectionality],
      };

      return {
        conditions: [intersectionalityQueryFilter],
      };
    });

    intersectionalityConjunction = {
      conjunction: QueryConjunction.AND,
      groups: [...intersectionaltyConditions],
    };
  }

  if (selectedFilters[DrupalFiltersPropertyKeys.tags].length > 0) {
    baseConditions.push({
      field: "field_keywords",
      operator: QueryOperators.IN,
      value: [...selectedFilters[DrupalFiltersPropertyKeys.tags]],
    });
  }

  let combinedIllinoisStandards: Array<string> = [];

  if (selectedFilters[DrupalFiltersPropertyKeys.illinoisStandards].length > 0) {
    combinedIllinoisStandards.push(
      ...selectedFilters[DrupalFiltersPropertyKeys.illinoisStandards]
    );
  }

  if (selectedFilters[DrupalFiltersPropertyKeys.academicYears].length > 0) {
    selectedFilters[DrupalFiltersPropertyKeys.academicYears].forEach(
      (year: string) => {
        const academicYearTerm =
          taxonomyFilters.academicYears &&
          taxonomyFilters.academicYears.find(
            (termYear: AcademicYearTermItem) => termYear.key === year
          );

        if (academicYearTerm) {
          const illinoisCodesFromYear: Array<string> = academicYearTerm.codes.map(
            (codeTerm: TermFilterItem) => codeTerm.key
          );
          combinedIllinoisStandards.push(...illinoisCodesFromYear);
        }
      }
    );
  }

  if (combinedIllinoisStandards.length > 0) {
    baseConditions.push({
      field: "field_illinois_standards",
      operator: QueryOperators.IN,
      value: Array.from(new Set(combinedIllinoisStandards)),
    });
  }

  let titleConditions: Array<EntityQueryFilterConditionInput> = [];

  if (selectedFilters[DrupalFiltersPropertyKeys.title].length > 0) {
    titleConditions.push({
      field: "title",
      operator: QueryOperators.LIKE,
      value: `%${selectedFilters[DrupalFiltersPropertyKeys.title]}%`,
    });

    titleConditions.push({
      field: "body",
      operator: QueryOperators.LIKE,
      value: `%${selectedFilters[DrupalFiltersPropertyKeys.title]}%`,
    });

    titleConditions.push({
      field: "field_lpei_summary",
      operator: QueryOperators.LIKE,
      value: `%${selectedFilters[DrupalFiltersPropertyKeys.title]}%`,
    });
  }

  const baseFilters: EntityQueryFilterInput = {
    conjunction: QueryConjunction.AND,
    groups: [
      { conjunction: QueryConjunction.OR, conditions: [...titleConditions] },
      { conditions: [...baseConditions] },
      { ...contributionsConjunction },
      { ...intersectionalityConjunction },
    ],
  };

  return {
    ...baseFilters,
  };
}

export default convertSelectedFiltersToConditions;
