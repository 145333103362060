import React from "react";
import ReactDOM from "react-dom";

import App from "components/app";

import "styles/theme.scss";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
