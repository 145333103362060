import {
  AcademicYearTermChildCodesItem,
  AcademicYearTermItem,
  AcademicYearTermFromDrupal,
} from "types/taxonomyTerms";

function formatAcademicYearsFromDrupal(
  taxonomyTerms: Array<AcademicYearTermFromDrupal>
): Array<AcademicYearTermItem> | null {
  if (!taxonomyTerms || taxonomyTerms.length < 1) {
    return null;
  }

  return taxonomyTerms.map((item: AcademicYearTermFromDrupal) => {
    const codes = item.fieldAssociatedCodes.map(
      (code: AcademicYearTermChildCodesItem) => ({
        key: code.targetId.toString(),
        value: code.entity.entityLabel,
      })
    );

    return {
      key: item.entityId,
      value: item.entityLabel,
      codes,
    };
  });
}

export default formatAcademicYearsFromDrupal;
