import React from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

import { DRUPAL_API_BASE_URL } from "constants/urls";

const client = new ApolloClient({
  uri: DRUPAL_API_BASE_URL,
  cache: new InMemoryCache(),
});

function GraphQLProvider({ children }: { children: React.ReactNode }) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default GraphQLProvider;
