import { gql } from "@apollo/client";

const Person = gql`
  fragment Person on NodePerson {
    status
    type {
      targetId
    }
    fieldLpeiSummary
    fieldPrimaryImage {
      derivative(style: PERSONPRIMARYIMAGE) {
        url
      }
    }
    entityUrl {
      path
    }
  }
`;

export default Person;
