import React from "react";

import { DrupalFilters, SelectedFilters } from "components/filters/types";

const defaultContextData: {
  selectedFilters: SelectedFilters;
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>;
  taxonomyFilters: DrupalFilters;
  setTaxonomyFilters: React.Dispatch<React.SetStateAction<DrupalFilters>>;
} = {
  selectedFilters: {
    academicYears: [],
    contributions: [],
    illinoisStandards: [],
    intersectionality: [],
    tags: [],
    title: "",
  },
  setSelectedFilters: () => {},
  taxonomyFilters: {
    illinoisStandards: [],
    contributions: [],
    intersectionality: [],
    academicYears: [],
    tags: [],
  },
  setTaxonomyFilters: () => {},
};

const FilterContext = React.createContext(defaultContextData);

export default FilterContext;
