import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import classNames from "classnames";
import ReactGA from "react-ga";

import Select, { ValueType } from "react-select";

import { DrupalFiltersPropertyKeys } from "components/filters/types";
import { FilterContext } from "components/filter-context";

import ContextAwareFilterToggle from "./ContextAwareFilterToggle";
import FilterTitle from "./FilterTitle";

import "./FilterAccordionToggle.scss";

type KeywordOption = { label: string; value: string };

function FilterAccordionTags({
  eventKey,
  title,
}: {
  eventKey: DrupalFiltersPropertyKeys;
  title: string;
}) {
  const {
    selectedFilters,
    setSelectedFilters,
    taxonomyFilters,
  } = React.useContext(FilterContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const [
    selectedValues,
    setSelectedValues,
  ] = React.useState<Array<KeywordOption> | null>(null);
  const [isFormDirty, setIsFormDirty] = React.useState<boolean>(false);

  const selectedFilterOptions =
    taxonomyFilters.tags && taxonomyFilters.tags.length > 0
      ? taxonomyFilters.tags.map((taxonomyTerm) => {
          const optionTerm: KeywordOption = {
            label: taxonomyTerm.value,
            value: taxonomyTerm.key,
          };

          return optionTerm;
        })
      : [];

  const handleOptionChanged = (selectedOption: ValueType<KeywordOption>) => {
    if (selectedOption && Array.isArray(selectedOption)) {
      setSelectedValues(selectedOption);
      setIsFormDirty(true);
      const selectedOptionToContext = selectedOption.map(
        (optionValue) => optionValue.value
      );

      setSelectedFilters({
        ...selectedFilters,
        [eventKey]: selectedOptionToContext,
      });

      ReactGA.event({
        category: "Filtering",
        action: `${title} Filter`,
        label: selectedOption.toString(),
      });
    } else {
      // In the case the value is 'null' reset the form.
      setSelectedValues(null);
      setIsFormDirty(false);
      setSelectedFilters({
        ...selectedFilters,
        [eventKey]: [],
      });
    }
  };

  const handleMenuOpen = () => {
    ReactGA.event({
      category: "Tags",
      action: "Expanded tag list",
    });
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    ReactGA.event({
      category: "Tags",
      action: "Closed tag list",
    });
    setIsMenuOpen(false);
  };

  const selectedFilterCount =
    selectedFilters[eventKey].length > 0
      ? `(${selectedFilters[eventKey].length})`
      : null;

  const cardBodyClasses = classNames(
    "FilterAccordionToggle-list FilterAccordionToggle-tags",
    {
      "FilterAccordionToggle--isMenuOpen": isMenuOpen,
      "FilterAccordionToggle--isMenuClosed": !isMenuOpen,
    }
  );

  if (selectedFilters[eventKey].length === 0 && isFormDirty) {
    setSelectedValues(null);
    setIsFormDirty(false);
  }

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        className="FilterAccordionToggle"
        eventKey={eventKey}
      >
        <ContextAwareFilterToggle eventKey={eventKey}>
          <FilterTitle
            eventKey={eventKey}
            selectedFilterCount={selectedFilterCount}
            title={title}
          />
        </ContextAwareFilterToggle>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className={cardBodyClasses}>
          <Select
            name="tags"
            placeholder="Start typing"
            options={selectedFilterOptions}
            onChange={handleOptionChanged}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            isMulti
            value={selectedValues}
          />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default FilterAccordionTags;
