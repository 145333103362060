import React from "react";
import ReactGA from "react-ga";

import { FilterContextProvider } from "components/filter-context";

import SearchPageQueryWrapper from "./SearchPageQueryWrapper";

function SearchPage() {
  const [pageLoaded, setPageLoaded] = React.useState<boolean>(false);
  React.useEffect(() => {
    const path = "/search";
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
  }, []);

  return (
    <FilterContextProvider>
      <SearchPageQueryWrapper
        pageLoaded={pageLoaded}
        setPageLoaded={setPageLoaded}
      />
    </FilterContextProvider>
  );
}

export default SearchPage;
