import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ReactGA from "react-ga";

import { AcademicYearTermItem, TermFilterItem } from "types/taxonomyTerms";
import FilterCheckbox from "components/filter-checkbox";
import {
  SelectedFilters,
  DrupalFiltersPropertyKeys,
} from "components/filters/types";
import { FilterContext } from "components/filter-context";
import { FormCheckboxTypes } from "types/forms";

import ContextAwareFilterToggle from "./ContextAwareFilterToggle";
import FilterTitle from "./FilterTitle";

import "./FilterAccordionToggle.scss";

const displayTerms = (
  terms: Array<AcademicYearTermItem | TermFilterItem>,
  eventKey: DrupalFiltersPropertyKeys,
  selectedFilters: SelectedFilters,
  handleCheckboxClick: (event: string) => void,
  type: FormCheckboxTypes
) => {
  return terms.map(({ key, value }: { key: string; value: string }) => (
    <FilterCheckbox
      key={key}
      handleCheckboxClick={handleCheckboxClick}
      id={key}
      isChecked={selectedFilters[eventKey].includes(key)}
      type={type}
      value={value}
    />
  ));
};

function FilterAccordionToggle({
  eventKey,
  helpText,
  title,
  terms,
  type = FormCheckboxTypes.CHECKBOX,
}: {
  eventKey: DrupalFiltersPropertyKeys;
  helpText?: React.ReactNode;
  title: string;
  terms: Array<AcademicYearTermItem | TermFilterItem>;
  type?: FormCheckboxTypes;
}) {
  const { selectedFilters, setSelectedFilters } = React.useContext(
    FilterContext
  );

  const handleCheckboxClick = (id: string) => {
    // Convert the selected filters array (for the event key) to a Set for easier property access.
    const selectedValuesForEventKey: Set<string> = new Set(
      selectedFilters[eventKey]
    );
    if (selectedValuesForEventKey.has(id)) {
      selectedValuesForEventKey.delete(id);
    } else {
      selectedValuesForEventKey.add(id);
      ReactGA.event({
        category: "Filtering",
        action: `${title} Filter`,
        label: id,
      });
    }

    // Change the Set back to an array and update the React state.
    setSelectedFilters({
      ...selectedFilters,
      [eventKey]: Array.from(selectedValuesForEventKey),
    });
  };

  const selectedFilterCount =
    selectedFilters[eventKey].length > 0
      ? `(${selectedFilters[eventKey].length})`
      : null;

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        className="FilterAccordionToggle"
        eventKey={eventKey}
      >
        <ContextAwareFilterToggle eventKey={eventKey}>
          <FilterTitle
            eventKey={eventKey}
            selectedFilterCount={selectedFilterCount}
            title={title}
          />
        </ContextAwareFilterToggle>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="FilterAccordionToggle-list">
          {helpText}
          {displayTerms(
            terms,
            eventKey,
            selectedFilters,
            handleCheckboxClick,
            type
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default FilterAccordionToggle;
