import { gql } from "@apollo/client";

const Milestone = gql`
  fragment Milestone on NodeMilestone {
    status
    type {
      targetId
    }
    fieldLpeiSummary
    fieldPrimaryImage {
      derivative(style: PERSONPRIMARYIMAGE) {
        url
      }
    }
    entityUrl {
      path
    }
  }
`;

export default Milestone;
