import { TermQueryResult } from "types/taxonomyTerms";
import { formatTermsFromDrupal } from "helpers";
import { DrupalFilters, DrupalFiltersPropertyKeys } from "./types";

function insertDataIntoState(
  stateObject: DrupalFilters,
  propertyKey: DrupalFiltersPropertyKeys,
  rawPropertyData: { [key: string]: TermQueryResult }
): DrupalFilters {
  const unformattedData =
    rawPropertyData[propertyKey] && rawPropertyData[propertyKey].entities;

  return {
    ...stateObject,
    [propertyKey]: formatTermsFromDrupal(unformattedData),
  };
}

export default insertDataIntoState;
