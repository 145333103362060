import { AcademicTermQueryResult } from "types/taxonomyTerms";
import { formatAcademicYearsFromDrupal } from "helpers";
import { DrupalFilters, DrupalFiltersPropertyKeys } from "./types";

function insertAcademicIntoState(
  stateObject: DrupalFilters,
  propertyKey: DrupalFiltersPropertyKeys,
  rawPropertyData: { [key: string]: AcademicTermQueryResult }
): DrupalFilters {
  const unformattedData =
    rawPropertyData[propertyKey] && rawPropertyData[propertyKey].entities;

  return {
    ...stateObject,
    [propertyKey]: formatAcademicYearsFromDrupal(unformattedData),
  };
}

export default insertAcademicIntoState;
