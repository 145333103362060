import { EntityQueryFilterConditionInput } from "types/queries";
import { QueryOperators } from "types/operators";

function getDefaultBaseConditions(): Array<EntityQueryFilterConditionInput> {
  return [
    { field: "field_is_lpei", value: "1" },
    {
      field: "type",
      operator: QueryOperators.IN,
      value: ["lesson_plan", "person", "milestone"],
    },
  ];
}

export default getDefaultBaseConditions;
