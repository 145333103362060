import React from "react";
import Button from "react-bootstrap/Button";
import ReactGA from "react-ga";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { MARKDOWN_BASE_URL } from "constants/urls";
import ReactPlayer from "react-player/lazy";

import "./WelcomeScreen.scss";

function WelcomeScreen() {
  const [overview, setOverview] = React.useState("");
  const [overviewExtra, setOverviewExtra] = React.useState("");
  const [legacy, setLegacy] = React.useState("");
  const [legacyExtra, setLegacyExtra] = React.useState("");
  const [thirdparty, setThirdparty] = React.useState("");
  const [thirdpartyExtra, setThirdpartyExtra] = React.useState("");

  const [overviewReadMore, setOverviewReadMore] = React.useState(false);
  const [legacyReadMore, setLegacyReadMore] = React.useState(false);
  const [thirdPartyReadMore, setThirdPartyReadMore] = React.useState(false);

  React.useEffect(() => {
    const path = "/";
    const overviewURL = `${MARKDOWN_BASE_URL}/search/overview.md`;
    const overviewExtraURL = `${MARKDOWN_BASE_URL}/search/overview-extra.md`;
    const legacyURL = `${MARKDOWN_BASE_URL}/search/legacy.md`;
    const legacyExtraURL = `${MARKDOWN_BASE_URL}/search/legacy-extra.md`;
    const thirdpartyURL = `${MARKDOWN_BASE_URL}/search/thirdparty.md`;
    const thirdpartyExtraURL = `${MARKDOWN_BASE_URL}/search/thirdparty-extra.md`;
    // Get Overview URL
    axios
      .get(overviewURL)
      .then((response) => {
        setOverview(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    // Get Overview Extra URL
    axios
      .get(overviewExtraURL)
      .then(function (response) {
        setOverviewExtra(response.data);
      })
      .catch(function (err) {
        console.error(err);
      });
    // Get Legacy URL
    axios
      .get(legacyURL)
      .then((response) => {
        setLegacy(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    // Get Legacy Extra URL
    axios
      .get(legacyExtraURL)
      .then((response) => {
        setLegacyExtra(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    // Get Third Party URL
    axios
      .get(thirdpartyURL)
      .then((response) => {
        setThirdparty(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
    axios
      .get(thirdpartyExtraURL)
      .then((response) => {
        setThirdpartyExtra(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
  }, []);
  const fireGoogleAnalyticsEvent = (
    sectionName: string,
    isToggled: boolean
  ) => {
    const action = isToggled ? "Expanded Text" : "Collapsed Text";

    ReactGA.event({
      category: "Welcome Read More",
      action,
      label: sectionName,
    });
  };

  const linkName = (readMore: boolean): string => {
    return readMore ? "Read Less << " : "Read More >> ";
  };

  return (
    <>
      <h1 className="WelcomeScreen-heading">HOW TO USE THE SEARCH PORTAL</h1>
      <ReactMarkdown>{overview}</ReactMarkdown>
      {overviewReadMore && <ReactMarkdown>{overviewExtra}</ReactMarkdown>}
      <div className="justify-content-center">
        {overviewReadMore && (
          <ReactPlayer
            url="https://vimeo.com/588425086"
            width="100%"
            controls
          />
        )}
      </div>
      <Button
        className="WelcomeScreen-readMore"
        onClick={() => {
          fireGoogleAnalyticsEvent("Overview", !overviewReadMore);
          setOverviewReadMore(!overviewReadMore);
        }}
        type="button"
        variant="link"
      >
        {linkName(overviewReadMore)}
      </Button>
      <ReactMarkdown>{legacy}</ReactMarkdown>
      {legacyReadMore && <ReactMarkdown>{legacyExtra}</ReactMarkdown>}
      <Button
        className="WelcomeScreen-readMore"
        onClick={() => {
          fireGoogleAnalyticsEvent(
            "Legacy Project Proprietary Lesson Plans",
            !legacyReadMore
          );
          setLegacyReadMore(!legacyReadMore);
        }}
        type="button"
        variant="link"
      >
        {linkName(legacyReadMore)}
      </Button>
      <ReactMarkdown>{thirdparty}</ReactMarkdown>
      {thirdPartyReadMore && <ReactMarkdown>{thirdpartyExtra}</ReactMarkdown>}
      <Button
        className="WelcomeScreen-readMore"
        onClick={() => {
          fireGoogleAnalyticsEvent(
            "2nd- and 3rd-Party Proprietary Lesson Plans",
            !thirdPartyReadMore
          );
          setThirdPartyReadMore(!thirdPartyReadMore);
        }}
        type="button"
        variant="link"
      >
        {linkName(thirdPartyReadMore)}
      </Button>
    </>
  );
}

export default WelcomeScreen;
