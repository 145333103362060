import React from "react";
import AccordionContext from "react-bootstrap/AccordionContext";

function ContextAwareFilterToggle({
  children,
  eventKey,
}: {
  children: React.ReactNode;
  eventKey: string;
}) {
  const currentEventKey = React.useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <h5 className="ContextAwareFilterToggle">
      <span className="ContextAwareFilterToggle-text">{children}</span>
      <span className="ContextAwareFilterToggle-toggle">
        {isCurrentEventKey ? "-" : "+"}
      </span>
    </h5>
  );
}

export default ContextAwareFilterToggle;
