import React from "react";
import Container from "react-bootstrap/Container";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Footer from "components/footer";
import GraphQLProvider from "components/graphql-provider";
import Header from "components/header";
import Home from "components/home";
import SearchPage from "components/search-page";
import PageNotFound from "components/page-not-found";

import ScrollToTop from "./ScrollToTop";

import "./App.scss";

function App() {
  const trackingId = "G-50F40G9TQX";
  ReactGA.initialize(trackingId);

  return (
    <GraphQLProvider>
      <Container className="App">
        <Header />
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/search" exact>
              <SearchPage />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </Container>
    </GraphQLProvider>
  );
}

export default App;
