import { gql } from "@apollo/client";

import {
  LessonPlanFragment,
  MilestoneFragment,
  PersonFragment,
} from "helpers/fragments";

const GET_PEOPLE_AND_MILESTONES = gql`
  query GetPeopleAndMilestones($filters: EntityQueryFilterInput) {
    nodeQuery(
      filter: $filters
      limit: 100
      sort: { field: "title", direction: ASC }
    ) {
      count
      entities {
        entityLabel
        entityId
        ...LessonPlan
        ...Milestone
        ...Person
      }
    }
  }
  ${LessonPlanFragment}
  ${MilestoneFragment}
  ${PersonFragment}
`;

export default GET_PEOPLE_AND_MILESTONES;
