const DRUPAL_BASE_URL = "https://lgbtqlegacy.org";
const DRUPAL_API_BASE_URL = `${DRUPAL_BASE_URL}/graphql`;
const DRUPAL_API_KEYWORDS_URL = `${DRUPAL_BASE_URL}/api/iicac/keywords`;
const MARKDOWN_BASE_URL =
  "https://legacyprojectchicago.github.io/illinois-lgbtq-search-markdown";

export {
  DRUPAL_API_BASE_URL,
  DRUPAL_BASE_URL,
  DRUPAL_API_KEYWORDS_URL,
  MARKDOWN_BASE_URL,
};
