import { gql } from "@apollo/client";

const LessonPlan = gql`
  fragment LessonPlan on NodeLessonPlan {
    status
    type {
      targetId
    }
    fieldLpeiSummary
    fieldPrimaryImage {
      derivative(style: PERSONPRIMARYIMAGE) {
        url
      }
    }
    entityUrl {
      path
    }
  }
`;

export default LessonPlan;
