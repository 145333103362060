import React from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

import "./PageNotFound.scss";

function PageNotFound() {
  React.useEffect(() => {
    const path = "/404";
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
  }, []);

  return (
    <div className="PageNotFound">
      <h1>Page Not Found</h1>
      <p>We're sorry, but the page requested cannot be displayed.</p>
      <Link to="/" className="PageNotFound-link">
        Back to home
      </Link>
    </div>
  );
}

export default PageNotFound;
