import React from "react";
import Row from "react-bootstrap/Row";

import "./Header.scss";

function Header() {
  return (
    <Row className="Header">
      <header className="Header-contentWrap">
        <img
          alt="Inclusive Curriculum Advisory Council of Illinois"
          className="Header-image"
          src="./logo2022.png"
        />
        <div className="Header-text">
          <h1 className="Header-title">
            Inclusive Curriculum Advisory Council of Illinois
          </h1>
          <p className="Header-subtitle">
            Equality Illinois | Illinois Safe Schools Alliance, a program of
            Public Health Institute of Metropolitan Chicago | The Legacy Project
          </p>
        </div>
      </header>
    </Row>
  );
}

export default Header;
