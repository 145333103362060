import { TaxonomyTermFromDrupal, TermFilterItem } from "types/taxonomyTerms";

function formatTermsFromDrupal(
  taxonomyTerms: Array<TaxonomyTermFromDrupal>
): Array<TermFilterItem> | null {
  if (!taxonomyTerms || taxonomyTerms.length < 1) {
    return null;
  }

  return taxonomyTerms.map((item) => ({
    key: item.entityId,
    value: item.entityLabel,
  }));
}

export default formatTermsFromDrupal;
