import React from "react";

import { ApolloDrupalQuery } from "types/queries";
import { ContentNode } from "types/nodes";

import { formatNodesFromDrupal } from "helpers";
import Error from "components/error";
import LoadingIndicator from "components/loading";
import ResultsCard from "components/results-card";
import SelectedConditions from "components/selected-conditions";
import { SelectedFilters } from "components/filters/types";

import "./Results.scss";

interface DrupalContentNodes {
  count: number;
  nodes: Array<ContentNode> | null;
}

interface SelectedQueryConditions {
  selectedQueryConditions: SelectedFilters;
}

function NoResults() {
  return (
    <div>
      <p>
        No results found for those filters. Please adjust your filter criteria
        and try again.
      </p>
    </div>
  );
}

function Results({
  error,
  data,
  loading,
  selectedQueryConditions,
}: ApolloDrupalQuery & SelectedQueryConditions) {
  const [contentNodes, setContentNodes] = React.useState<DrupalContentNodes>({
    count: 0,
    nodes: null,
  });

  React.useEffect(() => {
    if (data) {
      const nodes = formatNodesFromDrupal(data.nodeQuery.entities);
      setContentNodes({ count: data.nodeQuery.count, nodes });
    }
  }, [data]);

  if (error) return <Error />;

  return (
    <div className="Results">
      <h2 className="Results-heading">
        Your Search Results ({contentNodes ? contentNodes.count : 0}{" "}
        {contentNodes && contentNodes.count === 1 ? "item" : "items"})
      </h2>
      <SelectedConditions selectedQueryConditions={selectedQueryConditions} />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="Results-cardList">
          {contentNodes && contentNodes.nodes ? (
            contentNodes.nodes.map((node) => (
              <ResultsCard
                key={node.key}
                path={node.path}
                imagePath={node.primaryImage}
                title={node.title}
                summary={node.summary}
              />
            ))
          ) : (
            <NoResults />
          )}
        </div>
      )}
    </div>
  );
}

export default Results;
