import React from "react";
import { useQuery } from "@apollo/client";
import axios from "axios";

import { queries } from "helpers";
import {
  SelectedFilters,
  DrupalFilters,
  DrupalFiltersPropertyKeys,
} from "components/filters/types";
import insertDataIntoState from "components/filters/insertDataIntoState";
import insertAcademicIntoState from "components/filters/insertAcademicIntoState";
import { DRUPAL_API_KEYWORDS_URL } from "constants/urls";

import FilterContext from "./FilterContext";

function FilterContextProvider({ children }: { children: React.ReactNode }) {
  const [submitting, setSubmitting] = React.useState<boolean>(true);
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>(
    {
      academicYears: [],
      contributions: [],
      illinoisStandards: [],
      intersectionality: [],
      tags: [],
      title: "",
    }
  );
  const [taxonomyFilters, setTaxonomyFilters] = React.useState<DrupalFilters>({
    illinoisStandards: [],
    contributions: [],
    intersectionality: [],
    academicYears: [],
    tags: [],
  });

  const { loading, error, data } = useQuery(queries.GET_TAXONOMY_TERMS);

  React.useEffect(() => {
    if (data && !loading && !error) {
      let dataForState: DrupalFilters = {};

      [
        DrupalFiltersPropertyKeys.illinoisStandards,
        DrupalFiltersPropertyKeys.contributions,
        DrupalFiltersPropertyKeys.intersectionality,
      ].forEach((vocabulary: DrupalFiltersPropertyKeys): void => {
        if (data[vocabulary] && data[vocabulary].entities) {
          dataForState = insertDataIntoState(dataForState, vocabulary, data);
        }
      });

      if (data.academicYears && data.academicYears.entities) {
        dataForState = insertAcademicIntoState(
          dataForState,
          DrupalFiltersPropertyKeys.academicYears,
          data
        );
      }

      setTaxonomyFilters({ ...taxonomyFilters, ...dataForState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  React.useEffect(() => {
    const getData = async () => {
      const response = await axios.get(DRUPAL_API_KEYWORDS_URL);
      setTaxonomyFilters({ ...taxonomyFilters, tags: response.data.data });
      setSubmitting(false);
    };

    if (submitting) {
      getData();
    }
  }, [submitting, taxonomyFilters]);

  const contextData = {
    selectedFilters,
    setSelectedFilters,
    taxonomyFilters,
    setTaxonomyFilters,
  };

  return (
    <FilterContext.Provider value={contextData}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;
