export enum QueryOperators {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  SMALLER_THAN = "SMALLER_THAN",
  SMALLER_THAN_OR_EQUAL = "SMALLER_THAN_OR_EQUAL",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  IN = "IN",
  NOT_IN = "NOT_IN",
  LIKE = "LIKE",
  NOT_LIKE = "NOT_LIKE",
  BETWEEN = "BETWEEN",
  NOT_BETWEEN = "NOT_BETWEEN",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL",
}
