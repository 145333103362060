import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { DRUPAL_BASE_URL } from "constants/urls";

import "./ResultsCard.scss";

interface ResultsCardProps {
  path: string;
  imagePath: string;
  title: string;
  summary: string;
}

function ResultsCard({ path, imagePath, title, summary }: ResultsCardProps) {
  return (
    <Row className="ResultsCard">
      <Col xs={2}>
        <img className="ResultsCard-image" src={imagePath} alt={title} />
      </Col>
      <Col xs={10}>
        <h3 className="ResultsCard-heading">{title}</h3>
        <p className="ResultsCard-summary">{summary}</p>
        <a
          className="ResultsCard-link"
          href={`${DRUPAL_BASE_URL}${path}?previewLessonPlans=1`}
          rel="noopener noreferrer"
          target="_blank"
        >
          See lesson plan →
        </a>
      </Col>
    </Row>
  );
}

export default ResultsCard;
