import { gql } from "@apollo/client";

import { AcademicYearFragment } from "helpers/fragments";

const GET_TAXONOMY_TERMS = gql`
  query GetTaxonomyTerms {
    illinoisStandards: taxonomyTermQuery(
      limit: 100
      offset: 0
      filter: {
        conditions: [
          { operator: EQUAL, field: "vid", value: ["illinois_standards"] }
        ]
      }
    ) {
      entities {
        entityLabel
        entityId
      }
    }
    contributions: taxonomyTermQuery(
      limit: 100
      offset: 0
      filter: {
        conditions: [
          { operator: EQUAL, field: "vid", value: ["contributions"] }
        ]
      }
      sort: [{ field: "name", direction: ASC }]
    ) {
      entities {
        entityLabel
        entityId
      }
    }
    intersectionality: taxonomyTermQuery(
      limit: 100
      offset: 0
      filter: {
        conditions: [
          { operator: EQUAL, field: "vid", value: ["intersectionality"] }
        ]
      }
      sort: [{ field: "name", direction: ASC }]
    ) {
      entities {
        entityLabel
        entityId
      }
    }
    academicYears: taxonomyTermQuery(
      limit: 100
      offset: 0
      filter: {
        conditions: [
          { operator: EQUAL, field: "vid", value: ["academic_years"] }
        ]
      }
    ) {
      entities {
        entityLabel
        entityId
        ...AcademicYear
      }
    }
  }
  ${AcademicYearFragment}
`;

export default GET_TAXONOMY_TERMS;
