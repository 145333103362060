import { ApolloError } from "@apollo/client";
import { NodeQueryResult } from "./nodes";
import { QueryOperators } from "./operators";

export enum QueryConjunction {
  AND = "AND",
  OR = "OR",
}

export interface ApolloDrupalQuery {
  error?: ApolloError;
  data?: NodeQueryResult;
  loading: boolean;
}

export interface ApolloDrupalLazyQueryAction {
  search?: (variables: any) => void;
}

export interface EntityQueryFilterConditionInput {
  field: string;
  operator?: QueryOperators;
  value: Array<string> | string;
}

export interface EntityQueryFilterInput {
  conditions?: Array<EntityQueryFilterConditionInput>;
  groups?: Array<EntityQueryFilterInput>;
  conjunction?: QueryConjunction;
}
