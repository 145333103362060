import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsFillQuestionCircleFill } from "react-icons/bs";

import { DrupalFiltersPropertyKeys } from "components/filters/types";

import "./FilterTitle.scss";

function FilterTitle({
  eventKey,
  selectedFilterCount,
  title,
}: {
  eventKey: DrupalFiltersPropertyKeys;
  selectedFilterCount: string | null;
  title: string;
}) {
  let tooltipText = "";

  switch (eventKey) {
    case DrupalFiltersPropertyKeys.academicYears:
      tooltipText =
        "Each code provided aligns either to a specific elementary school year or range of school years covering early childhood, middle school, or high school. Using these codes, we have mapped out a search protocol option to help teachers who want to explore these resources based on the specific year in school you have been assigned to teach. Click on all which apply to your search.";
      break;
    case DrupalFiltersPropertyKeys.contributions:
      tooltipText =
        "Each selection you choose may align to more than one specific subject area (e.g. literature, history, etc.). By analyzing the fields of contributions (e.g. Journalism, Medicine, Engineering, Athletics, etc.) associated with the lesson plans in the database, we are able to connect individual achievements to the related school subjects typically taught in Illinois Public Schools. This search option is provided to help teachers think creatively about how historically significant achievements impacted the school subject(s) being taught. Click on all which apply to your search.";
      break;
    case DrupalFiltersPropertyKeys.illinoisStandards:
      tooltipText =
        "The Illinois Social Science Learning Standards listed are drawn from the State’s most-current guidelines to help teachers and students explore the relationship between individuals and society through the study of civics, economics, geography, history and other subjects. Your students will emerge with the knowledge, skills, attitudes, and behaviors necessary to be informed and effective citizens. Click on all which apply to your search.";
      break;
    case DrupalFiltersPropertyKeys.intersectionality:
      tooltipText =
        "In most cases, each person covered by materials in the database lived at the intersection of various identities (e.g. Black/African American, Latinx, Lesbian, Transgender, etc.) which shaped their experiences while informing their work, their motivations, and their outcomes and achievements. By exploring these resources through the lens of intersectionality, a teacher can make every lesson taught more inclusive and affirming for diverse classrooms. Click on all which apply to your search.";
      break;
    case DrupalFiltersPropertyKeys.tags:
      tooltipText =
        "Every lesson plan has been assigned 'Tags' based on common metatags used in Web Search Analytics. If you have been searching at other sites for specific content based on common tags, enter those terms here. We recommend entering one term at a time.";
      break;
    case DrupalFiltersPropertyKeys.title:
      tooltipText =
        "Use this filter to search for 'Keywords' likely to be found in the title or body of the text. We recommend entering one term at a time.";
      break;
    default:
      tooltipText = "";
      break;
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip
          className="FilterTitle-tooltip"
          id={`button-tooltip-${eventKey}`}
        >
          {tooltipText}
        </Tooltip>
      }
    >
      <div className="FilterTitle">
        <span className="FilterTitle-icon">
          <BsFillQuestionCircleFill />
        </span>{" "}
        <span className="FilterTitle-title">{title}</span>{" "}
        <span className="FilterTitle-selectedCount">{selectedFilterCount}</span>
      </div>
    </OverlayTrigger>
  );
}

export default FilterTitle;
