import { ContentNodeFromDrupal, ContentNode } from "types/nodes";

function formatNodesFromDrupal(
  nodes: Array<ContentNodeFromDrupal>
): Array<ContentNode> | null {
  if (!nodes || nodes.length < 1) {
    return null;
  }

  return nodes.map((node) => ({
    key: node.entityId,
    title: node.entityLabel,
    path: node.entityUrl.path,
    type: node.type.targetId,
    primaryImage: node.fieldPrimaryImage.derivative.url,
    summary: node.fieldLpeiSummary,
  }));
}

export default formatNodesFromDrupal;
