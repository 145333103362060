import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLazyQuery } from "@apollo/client";
import ReactGA from "react-ga";

import Filters from "components/filters";
import Results from "components/results";
import { queries } from "helpers";
import { EntityQueryFilterInput } from "types/queries";
import { NodeQueryResult } from "types/nodes";
import { SelectedFilters } from "components/filters/types";
import WelcomeScreen from "components/welcome-screen";
import { FilterContext } from "components/filter-context";

import convertSelectedFiltersToConditions from "./convertSelectedFiltersToConditions";

import "./SearchPage.scss";
import getDefaultBaseConditions from "./getDefaultBaseConditions";

type QueryVariables = {
  filters: EntityQueryFilterInput;
};

const resetSelectedFilters = {
  academicYears: [],
  contributions: [],
  illinoisStandards: [],
  intersectionality: [],
  tags: [],
  title: "",
};

function SearchPageQueryWrapper({
  pageLoaded,
  setPageLoaded,
}: {
  pageLoaded: boolean;
  setPageLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { selectedFilters, taxonomyFilters } = React.useContext(FilterContext);
  const queryConditions: EntityQueryFilterInput = convertSelectedFiltersToConditions(
    selectedFilters,
    taxonomyFilters
  );
  const [selectedQueryConditions, setSelectedQueryConditions] = React.useState<
    SelectedFilters
  >(resetSelectedFilters);
  const [isWelcomeScreen, setIsWelcomeScreen] = React.useState<boolean>(true);
  const [search, { error, data, loading }] = useLazyQuery<
    NodeQueryResult,
    QueryVariables
  >(queries.GET_PEOPLE_AND_MILESTONES, {
    variables: { filters: { conditions: getDefaultBaseConditions() } },
  });

  const onHandleSubmit = () => {
    ReactGA.event({
      category: "Search",
      action: "Submitted search",
    });
    setIsWelcomeScreen(false);
    setSelectedQueryConditions(selectedFilters);
    search({ variables: { filters: queryConditions } });
  };

  const onHandleReset = () => {
    ReactGA.event({
      category: "Search",
      action: "Reset search",
    });
    setIsWelcomeScreen(true);
    setSelectedQueryConditions(resetSelectedFilters);
  };

  return (
    <Row className="SearchPage">
      <Col className="SearchPage-filters" md={3}>
        <Filters
          onHandleSubmit={onHandleSubmit}
          onHandleReset={onHandleReset}
        />
      </Col>
      <Col className="SearchPage-results" md={9}>
        {isWelcomeScreen ? (
          <WelcomeScreen />
        ) : (
          <Results
            error={error}
            data={data}
            loading={loading}
            selectedQueryConditions={selectedQueryConditions}
          />
        )}
      </Col>
    </Row>
  );
}

export default SearchPageQueryWrapper;
