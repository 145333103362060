import React from "react";

import { FilterContext } from "components/filter-context";
import {
  DrupalFilters,
  DrupalFiltersPropertyKeys,
  SelectedFilters,
} from "components/filters/types";
import { AcademicYearTermItem, TermFilterItem } from "types/taxonomyTerms";

import "./SelectedConditions.scss";

function SelectedConditions({
  selectedQueryConditions,
}: {
  selectedQueryConditions: SelectedFilters;
}) {
  const {
    taxonomyFilters,
  }: { taxonomyFilters: DrupalFilters } = React.useContext(FilterContext);

  const getTaxonomyTermNameFromKey = (
    terms?: Array<TermFilterItem | AcademicYearTermItem>,
    termKey?: string
  ) => {
    if (terms && termKey) {
      const taxonomyTerm = terms.find((term) => term.key === termKey);
      return taxonomyTerm && taxonomyTerm.value;
    }
  };

  const getTaxonomyFilters = (
    filterHeading: string,
    terms?: Array<TermFilterItem | AcademicYearTermItem>,
    selectedTerms?: Array<string>
  ) => {
    if (terms && selectedTerms && selectedTerms.length > 0) {
      const termNames = selectedTerms.map((termKey) =>
        getTaxonomyTermNameFromKey(terms, termKey)
      );

      return (
        <div className="SelectedConditions-container">
          <h5>{filterHeading}</h5>
          <p>{termNames.join(", ")}</p>
        </div>
      );
    }
  };

  const allFiltersEmpty =
    selectedQueryConditions &&
    selectedQueryConditions[DrupalFiltersPropertyKeys.contributions].length ===
      0 &&
    selectedQueryConditions[DrupalFiltersPropertyKeys.intersectionality]
      .length === 0 &&
    selectedQueryConditions[DrupalFiltersPropertyKeys.illinoisStandards]
      .length === 0 &&
    selectedQueryConditions[DrupalFiltersPropertyKeys.academicYears].length ===
      0 &&
    selectedQueryConditions[DrupalFiltersPropertyKeys.tags].length === 0 &&
    selectedQueryConditions[DrupalFiltersPropertyKeys.title].length === 0;

  return (
    <>
      {!allFiltersEmpty && (
        <div className="SelectedConditions">
          {getTaxonomyFilters(
            "Subject/Field",
            taxonomyFilters.contributions,
            selectedQueryConditions[DrupalFiltersPropertyKeys.contributions]
          )}
          {getTaxonomyFilters(
            "Intersectionality",
            taxonomyFilters.intersectionality,
            selectedQueryConditions[DrupalFiltersPropertyKeys.intersectionality]
          )}
          {getTaxonomyFilters(
            "Curriculum Code",
            taxonomyFilters.illinoisStandards,
            selectedQueryConditions[DrupalFiltersPropertyKeys.illinoisStandards]
          )}
          {getTaxonomyFilters(
            "Academic Years",
            taxonomyFilters.academicYears,
            selectedQueryConditions[DrupalFiltersPropertyKeys.academicYears]
          )}
          {getTaxonomyFilters(
            "Tags",
            taxonomyFilters.tags,
            selectedQueryConditions[DrupalFiltersPropertyKeys.tags]
          )}
          {selectedQueryConditions[DrupalFiltersPropertyKeys.title].length >
            0 && (
            <div className="SelectedConditions-container">
              <h5>Keywords</h5>
              <p>{selectedQueryConditions[DrupalFiltersPropertyKeys.title]}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SelectedConditions;
