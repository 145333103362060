import { gql } from "@apollo/client";

const AcademicYear = gql`
  fragment AcademicYear on TaxonomyTermAcademicYears {
    fieldAssociatedCodes {
      targetId
      entity {
        entityLabel
      }
    }
  }
`;

export default AcademicYear;
