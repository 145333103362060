import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ReactGA from "react-ga";

import { DrupalFiltersPropertyKeys } from "components/filters/types";
import { FilterContext } from "components/filter-context";

import ContextAwareFilterToggle from "./ContextAwareFilterToggle";
import FilterTitle from "./FilterTitle";

import "./FilterAccordionToggle.scss";

declare type FormControlElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

function FilterAccordionTitle({
  eventKey,
  title,
}: {
  eventKey: DrupalFiltersPropertyKeys;
  title: string;
}) {
  const { selectedFilters, setSelectedFilters } = React.useContext(
    FilterContext
  );
  const updateTitleInSelectedFilters = (elementTarget: string) => {
    setSelectedFilters({
      ...selectedFilters,
      [eventKey]: elementTarget,
    });
  };

  const handleTitleChanged = (event: React.ChangeEvent<FormControlElement>) => {
    ReactGA.event({
      category: "Filtering",
      action: `${title} Filter`,
      label: event.target.value,
    });

    updateTitleInSelectedFilters(event.target.value);
  };

  const selectedFilterCount =
    selectedFilters[eventKey].length > 0 ? `(1)` : null;

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        className="FilterAccordionToggle"
        eventKey={eventKey}
      >
        <ContextAwareFilterToggle eventKey={eventKey}>
          <FilterTitle
            eventKey={eventKey}
            selectedFilterCount={selectedFilterCount}
            title={title}
          />
        </ContextAwareFilterToggle>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="FilterAccordionToggle-list">
          <Form.Control
            onChange={handleTitleChanged}
            type="title"
            value={selectedFilters[eventKey]}
            placeholder="Search by keywords"
          />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default FilterAccordionTitle;
