import { AcademicYearTermItem, TermFilterItem } from "types/taxonomyTerms";

export interface SelectedFilters {
  academicYears: Array<string>;
  contributions: Array<string>;
  illinoisStandards: Array<string>;
  intersectionality: Array<string>;
  tags: Array<string>;
  title: string;
}

export interface DrupalFilters {
  illinoisStandards?: Array<TermFilterItem>;
  contributions?: Array<TermFilterItem>;
  intersectionality?: Array<TermFilterItem>;
  academicYears?: Array<AcademicYearTermItem>;
  tags?: Array<TermFilterItem>;
}

export enum DrupalFiltersPropertyKeys {
  academicYears = "academicYears",
  contributions = "contributions",
  illinoisStandards = "illinoisStandards",
  intersectionality = "intersectionality",
  tags = "tags",
  title = "title",
}
